import { useState, useContext, useEffect } from "react";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { DappContext } from "../../Context/Dapp.context";
import { ERC20_ABI } from "../../Utils/Constants";
import { parseEther } from "ethers/lib/utils.js";
import { ethers } from "ethers";
import { NotifySuccess } from "../Notify/Notify";
import { motion } from "framer-motion";
import { splitXContract } from "../../Utils/Constants";

const fadeUp = {
  offScreen: {
    opacity: 0,
    y: 50,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.15,
    },
  },
};

const ApproveModal = () => {
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [approvalSuccess, setApprovalSuccess] = useState(false);
  const {
    tokenAddress,
    total,
    setModalOpen,
    setApproved,
    isApproved,
    isModalOpen,
  } = useContext(DappContext);

  // ============================ 📝 APPROVE CONTRACT WRITE 📝 ============================= //
  const { config } = usePrepareContractWrite({
    address: tokenAddress,
    abi: ERC20_ABI,
    functionName: "approve",
    args: [splitXContract.address, ethers.utils.parseEther(total)],
  });
  const {
    write: Approve,
    data: approveData,
    isSuccess: isApprovalStarted,
  } = useContractWrite(config);
  const { isSuccess: approveTxSuccess } = useWaitForTransaction({
    hash: approveData?.hash,
  });
  const approveHandler = () => {
    Approve?.();
  };

  // ====================== 👂 APPROVE TXN EVENT LISTENER ✅ ======================= //
  useEffect(() => {
    if (isApprovalStarted) {
      setBtnLoading(true);
    }
  }, [isApprovalStarted]);

  useEffect(() => {
    if (approveTxSuccess) {
      // TODO do i need to add refetch here?
      setBtnLoading(false);
      setApprovalSuccess(true);
      setApproved(true);
      NotifySuccess("Approval Successful !");
    }
  }, [approveTxSuccess]);

  return (
    <div className="flex justify-center items-center ">
      <div
        onClick={() => setModalOpen(false)}
        className="h-screen fixed top-0 z-5  w-full flex justify-center items-center bg-black bg-opacity-60 overflow-hidden"
      />
      <div className="h-screen fixed top-0   flex justify-center items-center  px-10 overflow-hidden">
        <motion.div
          variants={fadeUp}
          initial="offScreen"
          animate={isModalOpen ? "onScreen" : "offScreen"}
          className="h-[350px] w-[500px] rounded-3xl bg-[#1A1B1F]  border-[0.25px] border-[#37383D] relative flex justify-center items-center z-10"
        >
          <div
            onClick={() => setModalOpen(false)}
            className="h-[35px] w-[35px] bg-[#333439] rounded-full absolute top-6 right-6 border-[0.25px]  border-gray-500 text-gray-300 flex justify-center items-center text-[28px] cursor-pointer"
          >
            x
          </div>

          {/*  */}
          <div className="">
            <p className="text-[30px] text-center leading-tight text-[#00edff]">
              THIS REQUIRES APPROVAL . . .
            </p>
            <p className="text-[16px] text-center px-8 pt-4">
              You currently have an insufficient amount of allowance to execute
              this transaction. Please approve our smart contract below in order
              to grant allowance to perform this request
            </p>

            <div className="px-14 pt-8 flex items-center justify-between pb-10">
              <p className="text-[24px]">APPROVE SEND</p>

              {isApproved ? (
                <div className="text-[#00edff] text-[24px] ">APPROVED</div>
              ) : (
                <button
                  disabled={isBtnLoading}
                  onClick={approveHandler}
                  className={isBtnLoading ? "loadBtnInactive" : "loadBtn"}
                >
                  {isBtnLoading ? (
                    <div className="flex items-center gap-2">
                      <div className="grayLoader" />
                      Approving...
                    </div>
                  ) : (
                    <p>Approve</p>
                  )}
                </button>
              )}
            </div>

            <div className="flex justify-center">
              <button
                onClick={() => setModalOpen(false)}
                disabled={!approvalSuccess}
                className={
                  approvalSuccess
                    ? "loadBtn"
                    : "loadBtnInactive  absolute bottom-8"
                }
              >
                Continue
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ApproveModal;
