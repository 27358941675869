import abi from "./erc20-abi.json";

import sendAbi from "./splitX-abi.json";

export const bnbAddress = "0xB8c77482e45F1F44dE1745F52C74426C631bDD52";
export const bnbTestnet = "0xfc1c82c5edeb51082cf30fddb434d2cbda1f6924";

export const usdcAddress = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
export const usdcTestnet = "0x2f3a40a3db8a7e3d09b0adfefbce4f6f81927557";

export const usdtAddress = "0xdac17f958d2ee523a2206206994597c13d831ec7";
export const usdtTestnet = "0xfad6367e97217cc51b4cd838cc086831f81d38c2";

const splitContractAddress = "0xe526b4b139bf031835411477e6f73c8aa774d659";

// ===========================  SEND IT ABI ===============================
const SPLITX_ABI = sendAbi;

export const splitXContract = {
  address: splitContractAddress,
  abi: SPLITX_ABI,
};

// =========================== ERC 20 STANDARD ABI ===============================
export const ERC20_ABI = abi;

export const shortenAddress = (address) =>
  `${address.slice(0, 2)}...${address.slice(address.length - 4)}`;
