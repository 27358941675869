import { useContext, useEffect, useState } from "react";
import { shortenAddress } from "../../Utils/Constants";
import { DappContext } from "../../Context/Dapp.context";
import { formatEther, parseEther } from "ethers/lib/utils.js";
import { ethers } from "ethers";
import {
  useWaitForTransaction,
  useContractWrite,
  usePrepareContractWrite,
  useNetwork,
  useBalance,
  useAccount,
} from "wagmi";
import { splitXContract } from "../../Utils/Constants";
import { motion } from "framer-motion";
import { NotifySuccess, NotifyError } from "../Notify/Notify";

const fadeUp = {
  offScreen: {
    opacity: 0,
    y: 50,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.15,
    },
  },
};

const ReviewAndSend = () => {
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [hash, setHash] = useState("");
  const [viewTxButton, setViewTxButton] = useState(false);
  const {
    values,
    addresses,
    tokenData,
    total,
    tokenAddress,
    isCheckoutModalOpen,
    setCheckoutModalOpen,
    isEthSelected,
  } = useContext(DappContext);
  const { chain } = useNetwork();
  const { address } = useAccount();

  console.log("chainId", chain.id);

  // ============================ 📝 APPROVE CONTRACT WRITE 📝 ============================= //
  // SEND ETHER
  const { config: sendEtherConfig } = usePrepareContractWrite({
    ...splitXContract,
    functionName: "sendEther",
    args: [addresses, values],
    overrides: { value: ethers.utils.parseEther(total) },
  });
  const {
    write: SendEther,
    data: sendEtherData,
    isSuccess: isEtherSendStarted,
  } = useContractWrite(sendEtherConfig);
  const { isSuccess: EtherSendTxSuccess } = useWaitForTransaction({
    hash: sendEtherData?.hash,
  });

  // SEND ERC TOKENS
  const { config: sendErcConfig } = usePrepareContractWrite({
    ...splitXContract,
    functionName: "sendErcTokens",
    args: [tokenAddress, addresses, values],
  });
  const {
    write: SendERC20,
    data: sendErcData,
    isSuccess: isErcSendStarted,
  } = useContractWrite(sendErcConfig);
  const { isSuccess: ErcSendTxSuccess } = useWaitForTransaction({
    hash: sendErcData?.hash,
  });

  // ====================== 👂 TXN EVENT LISTENER ✅ ======================= //

  useEffect(() => {
    if (isEtherSendStarted) {
      setBtnLoading(true);
      setHash(sendEtherData?.hash);
      setViewTxButton(true);
    }
  }, [isEtherSendStarted]);

  useEffect(() => {
    if (EtherSendTxSuccess) {
      setBtnLoading(false);
      NotifySuccess("Transaction Successful !");
    }
  }, [EtherSendTxSuccess]);

  useEffect(() => {
    if (isErcSendStarted) {
      setBtnLoading(true);
      setHash(sendErcData?.hash);
      setViewTxButton(true);
    }
  }, [isErcSendStarted]);

  useEffect(() => {
    if (ErcSendTxSuccess) {
      setBtnLoading(false);
      NotifySuccess("Transaction Successful !");
    }
  }, [ErcSendTxSuccess]);

  // ============================ ETHER BALANCE ============================= //
  const {
    data: balanceData,
    // refetch: fetchBalance
  } = useBalance({
    address: address,
    // watch: true,
  });

  const sendTxnHandler = () => {
    if (isEthSelected) {
      if (Number(balanceData.formatted) < Number(total)) {
        NotifyError("ERROR: Insufficient Funds !");
      } else {
        SendEther?.();
      }
    } else if (
      Number(ethers.utils.formatEther(tokenData.tokenBalance.toString())) <
      Number(total)
    ) {
      NotifyError("ERROR: Insufficient Funds !");
    } else {
      SendERC20?.();
    }
  };

  console.log("balance:", balanceData.formatted);

  return (
    <div className="flex justify-center items-center">
      <div
        onClick={() => setCheckoutModalOpen(false)}
        className="h-screen fixed top-0 z-5  w-full flex justify-center items-center bg-black bg-opacity-60 overflow-hidden"
      />
      <div className="h-screen fixed top-0  w-full flex justify-center items-center  px-10 overflow-hidden">
        {/*  */}
        <motion.div
          variants={fadeUp}
          initial="offScreen"
          animate={isCheckoutModalOpen ? "onScreen" : "offScreen"}
          className="h-[80%] w-[50%] rounded-3xl bg-[#1A1B1F]  border-[0.25px] border-[#37383D] relative  pt-10 z-10"
        >
          <div
            onClick={() => setCheckoutModalOpen(false)}
            className="h-[35px] w-[35px] bg-[#333439] rounded-full absolute top-6 right-6 border-[0.25px]  border-gray-500 text-gray-300 flex justify-center items-center text-[28px] cursor-pointer"
          >
            x
          </div>

          <div className="text-[40px]  text-[#00edff] pb-8">
            <p className="text-center">REVIEW & SEND</p>
          </div>

          <p className="text-start text-[#00edff] text-[16px] pl-24 pb-2">{`*Sending ${Number(
            total
          ).toFixed(2)} ${tokenData.tokenSymbol} to ${
            addresses.length
          } recipients`}</p>

          <div className=" flex justify-between  px-24 text-[24px]  h-[350px] overflow-y-scroll">
            <div className="">
              {addresses.map((address) => (
                <div className="">{shortenAddress(address)}</div>
              ))}
            </div>
            <div>
              {values.map((value) => (
                <div className=" flex items-center gap-4 justify-end">
                  <p>
                    {Number(ethers.utils.formatEther(value.toString())).toFixed(
                      2
                    )}
                  </p>
                  <p className="text-[#00edff]">{tokenData.tokenSymbol}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end pr-24 pt-4 border-t-[0.25px] border-gray-500">
            <div className="flex items-center gap-4">
              <a
                href={
                  chain.id === 5
                    ? `https://goerli.etherscan.io/tx/${hash}`
                    : `https://etherscan.io/tx/${hash}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className={viewTxButton ? "viewTxnBtn py-1" : "hidden"}
              >
                VIEW TXN
              </a>
              <button
                onClick={sendTxnHandler}
                disabled={isBtnLoading}
                className={
                  isBtnLoading ? "loadBtnInactive py-1" : "loadBtn py-1 "
                }
              >
                {isBtnLoading ? (
                  <div className="flex gap-2 items-center">
                    <div className="grayLoader" />
                    SENDING...
                  </div>
                ) : (
                  <p>SEND</p>
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ReviewAndSend;
