import { createContext, useState, useEffect } from "react";
// import { useAccount, useContractReads } from "wagmi";
import { formatEther } from "ethers/lib/utils.js";
import { ethers } from "ethers";

export const DappContext = createContext();

export const DappProvider = ({ children }) => {
  const [tokenAddress, setTokenAddress] = useState("");
  const [isEthSelected, setEthSelected] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [values, setValues] = useState([]);
  const [total, setTotal] = useState("0");
  const [isApproved, setApproved] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCheckoutModalOpen, setCheckoutModalOpen] = useState(false);

  const [tokenData, setTokenData] = useState({
    tokenName: "",
    tokenSymbol: "",
    tokenSupply: "",
    tokenDecimals: "",
    tokenAllowance: "",
    tokenBalance: "",
  });

  useEffect(() => {
    const calculateTotal = () => {
      if (values.length === 0) {
        setTotal("0");
        return;
      }

      const totalBigNumber = values.reduce((accumulator, currentValue) => {
        return accumulator.add(currentValue);
      }, ethers.constants.Zero);

      if (isEthSelected === false) {
        if (tokenData.tokenAllowance.lt(totalBigNumber)) {
          setApproved(false);
        } else setApproved(true);
      }

      const totalEther = ethers.utils.formatEther(totalBigNumber.toString());
      setTotal(totalEther);
    };

    calculateTotal();
  }, [values]);

  console.log("isAPPROVED ?", isApproved);

  const value = {
    tokenAddress,
    setTokenAddress,
    isEthSelected,
    setEthSelected,
    values,
    setValues,
    addresses,
    setAddresses,
    total,
    tokenData,
    setTokenData,
    setModalOpen,
    isModalOpen,
    setApproved,
    isApproved,
    isCheckoutModalOpen,
    setCheckoutModalOpen,
  };

  return <DappContext.Provider value={value}>{children}</DappContext.Provider>;
};
