import { useContext } from "react";
import TokenSelector from "../../Components/TokenSelector/TokenSelector";
import RecipientsAndValues from "../../Components/RecipientsAndValues/RecipientsAndValues";
import { useAccount } from "wagmi";
import ApproveModal from "../../Components/ApproveModal/ApproveModal";
import { DappContext } from "../../Context/Dapp.context";
import ReviewAndSend from "../../Components/ReviewAndSend/ReviewAndSend";

const Home = () => {
  const { isConnected } = useAccount();
  const { isModalOpen, isCheckoutModalOpen } = useContext(DappContext);
  return (
    <div className="flex justify-center items-center bg-neutral-900">
      {isConnected ? (
        <div className="">
          <TokenSelector />
          <RecipientsAndValues />
          {isModalOpen ? <ApproveModal /> : <></>}
          {isCheckoutModalOpen ? <ReviewAndSend /> : <></>}
        </div>
      ) : (
        <div className="text-6xl lap:text-7xl font-bold flex justify-center pt-32 font-sans">
          CONNECT WALLET TO <span className="text-[#00edff] pl-6">SPLITX</span>
        </div>
      )}
    </div>
  );
};

export default Home;
