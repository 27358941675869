import React from "react";
import { ReactComponent as Logo } from "../../Assets/logo.0f2bf534.svg";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const Header = () => {
  return (
    <div className="py-10 px-10 flex justify-between items-center">
      <Logo className=" w-32" />
      <ConnectButton />
    </div>
  );
};

export default Header;
