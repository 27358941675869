import { useState, useContext } from "react";
import { ReactComponent as Eth } from "../../Assets/eth.svg";
import { ReactComponent as Usdc } from "../../Assets/usdc.svg";
import { ReactComponent as Usdt } from "../../Assets/usdt.svg";
import { ReactComponent as Bnb } from "../../Assets/bnb.svg";
import { DappContext } from "../../Context/Dapp.context";
import {
  bnbAddress,
  bnbTestnet,
  usdcAddress,
  usdcTestnet,
  usdtAddress,
  usdtTestnet,
} from "../../Utils/Constants";
import { ethers } from "ethers";
import { NotifyError, NotifySuccess } from "../Notify/Notify";
import { ERC20_ABI } from "../../Utils/Constants";
import { splitXContract } from "../../Utils/Constants";
import { useAccount, useNetwork } from "wagmi";

const selectors = [
  { id: 1, logo: Eth, tokenName: "Ethereum" },
  { id: 2, logo: Usdc, tokenName: "Usdc" },
  { id: 3, logo: Usdt, tokenName: "Usdt" },
  { id: 4, logo: Bnb, tokenName: "Bnb" },
  { id: 5, logo: Usdc, tokenName: "Custom" },
];

const TokenSelector = () => {
  const [activeButton, setActiveButton] = useState(0);
  const [fieldValue, setFieldValue] = useState("");
  const [isBtnLoading, setBtnLoading] = useState(false);
  const {
    setTokenAddress,
    tokenAddress,
    isEthSelected,
    setEthSelected,
    tokenData,
    setTokenData,
  } = useContext(DappContext);
  const { address } = useAccount();
  const { chain } = useNetwork();

  const handleSelection = (selectedId) => {
    if (selectedId === 1) {
      setActiveButton(1);
      setTokenAddress("");
      setFieldValue("ETH");
      setTokenData({
        tokenName: "Ethereum",
        tokenSymbol: "ETH",
        tokenSupply: "",
        tokenDecimals: "",
      });

      setEthSelected(true);
    } else if (selectedId === 2) {
      if (chain.id === 5) {
        setFieldValue(usdcTestnet);
      } else setFieldValue(usdcAddress);
      setActiveButton(2);
      setEthSelected(false);
    } else if (selectedId === 3) {
      if (chain.id === 5) {
        setFieldValue(usdtTestnet);
      } else setFieldValue(usdtAddress);
      setActiveButton(3);
      setEthSelected(false);
    } else if (selectedId === 4) {
      if (chain.id === 5) {
        setFieldValue(bnbTestnet);
      } else setFieldValue(bnbAddress);
      setActiveButton(4);
      setEthSelected(false);
    } else {
      setActiveButton(5);
      setEthSelected(false);
      setTokenAddress("");
      setFieldValue("");
    }
  };

  const handleChange = (event) => {
    setFieldValue(event.target.value);
  };

  const customContractHandler = async () => {
    if (fieldValue.length != 42) {
      NotifyError("ERROR: Invalid contract format !");
    } else {
      setBtnLoading(true);
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        // Create a new instance of the contract using its address and the ERC20 ABI
        const contract = new ethers.Contract(fieldValue, ERC20_ABI, signer);

        // Check if the contract implements the ERC20 methods and events
        const name = await contract.name();
        const symbol = await contract.symbol();
        const totalSupply = await contract.totalSupply();
        const balance = await contract.balanceOf(address);
        const allowance = await contract.allowance(
          address,
          splitXContract.address
        );
        const decimals = await contract.decimals();

        const tempData = {
          tokenName: name,
          tokenSymbol: symbol,
          tokenSupply: totalSupply,
          tokenDecimals: decimals,
          tokenAllowance: allowance,
          tokenBalance: balance,
        };

        setTokenData(tempData);

        // If the contract implements the above methods without throwing an error,
        // consider it as a valid ERC20 contract
        if (name && symbol && totalSupply) {
          console.log("CONTRACT VALID! :", name, symbol);
          NotifySuccess(`${symbol} successfully loaded !`);
          setTokenAddress(fieldValue);
          setBtnLoading(false);
        } else {
          console.log("CONTRACT INVALID");
          setBtnLoading(false);
        }
      } catch (error) {
        // Handle any errors that might occur during contract interaction
        console.log("CONTRACT INVALID ERROR");
        NotifyError("ERROR: Invalid contract !");
        setFieldValue("");
        setBtnLoading(false);
      }
    }
  };

  console.log("tokenData:", tokenData);

  return (
    <div className="">
      <div className=" w-full mx-auto max-w-6xl pt-14 pb-20">
        <p className="text-4xl pb-8 font-sans uppercase font-bold">
          Select Ether or Token
        </p>

        <div className="grid grid-cols-3 gap-10 pb-24">
          {selectors.map((token) => (
            <button
              className={`px-10 py-3 uppercase font-bold font-sans rounded-full
                ${
                  activeButton === token.id
                    ? "tokenBtnActive"
                    : "tokenBtnInactive"
                }
                  `}
              onClick={() => handleSelection(token.id)}
            >
              {<token.logo className="w-6 h-6" />}
              {token.tokenName}
            </button>
          ))}
        </div>

        <div>
          <p className="text-[20px] pb-2 font-sans uppercase">
            Contract Address :
          </p>

          <div className="flex gap-6">
            <input
              className={
                activeButton === 5 ? "inputFieldActive" : "inputFieldInactive"
              }
              type=""
              required
              placeholder="Load Token Address"
              spellCheck="false"
              readOnly={activeButton != 5}
              onChange={handleChange}
              // value={isEthSelected ? "ETH" : tokenAddress}
              value={fieldValue}
            />
            <button
              disabled={isBtnLoading || activeButton === 1}
              className={
                isBtnLoading || activeButton === 1
                  ? "loadBtnInactive"
                  : "loadBtn"
              }
              onClick={customContractHandler}
            >
              {isBtnLoading ? (
                <div className="flex gap-2 items-center">
                  <div className="grayLoader" />
                  Loading...
                </div>
              ) : (
                <div>Set Contract</div>
              )}
            </button>
          </div>

          <div className="h-[50px]">
            {tokenAddress.length > 1 || isEthSelected ? (
              <p className="pt-6 text-[18px] text-[#00edff]">
                Token currently set to:{" "}
                {`${tokenData.tokenName} - ${tokenData.tokenSymbol}`}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenSelector;
