import { useState, useContext, useEffect } from "react";
import { isAddress, parseEther, formatEther } from "ethers/lib/utils.js";
import { ethers } from "ethers";
import { NotifyError, NotifySuccess } from "../Notify/Notify";
import { DappContext } from "../../Context/Dapp.context";
import { motion } from "framer-motion";
// import { useNavigate } from "react-router-dom";

const fadeUp = {
  offScreen: {
    opacity: 0,
    y: 50,
  },
  onScreen: {
    y: 0,
    opacity: 100,
    transition: {
      ease: "easeIn",
      duration: 0.5,
    },
  },
};

const RecipientsAndValues = () => {
  const [inputValue, setInputValue] = useState("");
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [canCheckout, setCanCheckout] = useState(false);
  const {
    values,
    setValues,
    addresses,
    setAddresses,
    tokenAddress,
    isEthSelected,
    total,
    setModalOpen,
    isApproved,
    setCheckoutModalOpen,
  } = useContext(DappContext);
  // const navigate = useNavigate();

  // const goToCheckoutHandler = () => {
  //   navigate("/checkout");
  // };

  const isValidAddress = (address) => {
    return ethers.utils.isAddress(address);
  };

  const handleClick = () => {
    const lines = inputValue.split(/\r?\n/);

    const addressesArray = [];
    const valuesArray = [];
    setBtnLoading(true);
    try {
      lines.forEach((line) => {
        const [address, value] = line.split(/[ ,=]+/);

        if (!address || !value) {
          throw new Error(
            "Invalid format. Address and value must be provided on each line."
          );
        }

        if (!isValidAddress(address)) {
          throw new Error(`Invalid Ethereum address: ${address}`);
        }

        if (isNaN(value) || parseFloat(value) <= 0) {
          throw new Error(
            `Invalid value: ${value}. Value must be a positive number.`
          );
        }

        const valueInWei = ethers.utils.parseEther(value);

        addressesArray.push(address);
        valuesArray.push(valueInWei);
        // valuesArray.push(Number(valueInWei).toString());
      });

      setAddresses(addressesArray);
      setValues(valuesArray);
      NotifySuccess("Transactions successfully loaded");
      setBtnLoading(false);
      setCanCheckout(true);
    } catch (error) {
      // Handle the error here, for example, log it or display it to the user
      NotifyError("Invalid address or amount found !");
      setBtnLoading(false);
      // Optionally, you can clear the arrays to prevent any partially processed data from being used
      setAddresses([]);
      setValues([]);
      setCanCheckout(false);
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const checkoutHandler = () => {
    if (isEthSelected === false && isApproved === false) {
      setModalOpen(true);
    } else setCheckoutModalOpen(true);
  };

  // console.log("addys:", addresses);
  // console.log("amounts:", values);
  // console.log("total:", total);

  // DAS IS GUD
  // console.log(
  //   Number(formatEther(values[0].toString())) +
  //     Number(formatEther(values[2].toString()))
  // );
  // console.log("total", Number(formatEther(total.toString() ?? "0")));

  return (
    <div
      className={
        tokenAddress.length < 1 && !isEthSelected
          ? "hidden"
          : " overflow-hidden"
      }
    >
      <motion.div
        variants={fadeUp}
        initial="offScreen"
        animate={
          tokenAddress.length > 1 || isEthSelected ? "onScreen" : "offScreen"
        }
        viewport={{ once: true, amount: 0.3 }}
        className="w-full mx-auto max-w-6xl"
      >
        <p className="text-[30px] pb-2 font-sans font-bold uppercase">
          Add Recipients & Values
        </p>

        <div className="pb-6">
          <textarea
            className="outline-none bg-gray-700 h-[500px] w-[600px] pl-2"
            type=""
            required
            placeholder="Load recipient addresses, one per line ex: supports spaces, commas & equal 0xB8c77482e45F1F44dE1745F52C74426C631bDD52=7634453 0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48,88.20 0xdac17f958d2ee523a2206206994597c13d831ec7 9999.99"
            spellCheck="false"
            // readOnly={activeButton != 5}
            onChange={handleChange}
            value={inputValue}
          />
        </div>

        <div className="w-[600px] pb-24 flex justify-between">
          <p className="text-[18px] text-[#00edff]">
            Transactions Loaded : {values.length}
          </p>
          <button
            disabled={isBtnLoading}
            className={isBtnLoading ? "loadBtnInactive py-2 " : "loadBtn py-2 "}
            onClick={handleClick}
          >
            {isBtnLoading ? (
              <div className="flex items-center gap-2">
                <div className="grayLoader" />
                Loading Transactions...
              </div>
            ) : (
              <p>Load Transactions</p>
            )}
          </button>
        </div>

        <button
          onClick={checkoutHandler}
          disabled={!canCheckout}
          className={canCheckout ? "checkoutBtn" : "checkoutBtnInactive"}
        >
          REVIEW & CHECKOUT
        </button>
      </motion.div>
    </div>
  );
};

export default RecipientsAndValues;
