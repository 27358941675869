import Header from "./Components/Header/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Routes/Home/Home";

function App() {
  return (
    <div className="h-screen font-sans text-white bg-neutral-900">
      <Header />
      <Home />

      <ToastContainer />
    </div>
  );
}

export default App;
